import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';

const options = [
  { value: 'Save', label: 'Save', }, 
  { value: 'Export', label: 'Export', }, 
  { value: 'Duplicate', label: 'Duplicate', }, 
  { value: 'Close', label: 'Close', },
];

function Select({ value, handleId, nodeId }) {
  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            selects: {
              ...node.data.selects,
              [handleId]: evt.target.value,
            },
          };
        }

        return node;
      })
    );
  };

  return (
    <div className="document-node__select">
      <div>Action</div>
      <select className="nodrag" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      
    </div>
  );
}

function DocumentNode({ id, data }) {
  return (
    <>
        <Handle
            type="target"
            position={Position.Left}
            id="left-handle"
            //style={{ top: '15px' }} // Adjust the position as needed
        />
        <Handle type="source" position={Position.Right} id="right-handle" />
        <div className="document-node__header">
            <strong>Document</strong>
        </div>
        <div className="document-node__body">
            {Object.keys(data.selects).map((handleId) => (
            <Select key={handleId} nodeId={id} value={data.selects[handleId]} handleId={handleId} />
            ))}
        </div>
    </>
  );
}

export default memo(DocumentNode);
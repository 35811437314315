import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './context/AuthContext';
import { Navbar, Nav, Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';

const SetupPage = () => {
    const { auth, logout } = useAuth(); // Destructure auth from useAuth hook
    const navigate = useNavigate();
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [clientIDError, setClientIDError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    
    const [formData, setFormData] = useState({
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        country: '',
        phone: '',
    });

    const [ClientIdformData, setClientIdFormData] = useState({
        clientId: ''
    });

    const [DBformData, setDBFormData] = useState({
        serverHost: '',
        port: '',
        userName: '',
        DBpassword: '',
        dbName: '',
    });

    useEffect(() => { 
        const fetchData = async () => {
            try {
                setLoading(true);
                const userData = {
                    org: auth.user,
                    user_id: auth.user_id
                };
                const response = await axios.post('/api/setup_status', { userData }); // Replace with your API endpoint
                if (response.status === 200) {
                    setActiveStep(response.data.stage);
                }else{
                    // Set the active step to 2
                    setActiveStep(1);
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }finally{
                setLoading(false);
            }
        };

        if (auth.user) {
            fetchData();
        }
    }, [auth]);

    if (!auth.user) {
        return null;
    }


    const handleProfileClick = () => {
        setShowProfileModal(true);
    };

    const handleProfileClose = () => {
        setShowProfileModal(false);
    };
    
    const handleLogout = () => {
        logout(); // Call the logout function from the context
        navigate('/'); // Redirect to the login page
    };

    const handleMessages = (type, message) => {
        if (type === 'success') {
            setSuccessMessage(message);
            setTimeout(() => setSuccessMessage(''), 2000); // Clear message after 2 seconds
        } else if (type === 'error') {
            setErrorMessage(message);
            setTimeout(() => setErrorMessage(''), 2000); // Clear message after 2 seconds
        }
    };

    const handleClientIdSubmit = async (e) => {
        e.preventDefault();
        // if country is empty, return error
        console.log('handle Client Id Submit');
        if (!ClientIdformData.clientId) {
            setClientIDError(true);
            setErrorMessage('clientID is required.');
            return "clientID is required";
        }
        setClientIDError(false);
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');
        const updatedClientIDFormData = {
            ...ClientIdformData,
            org: auth.user,
            user_id: auth.user_id
        };
        try {
            const response = await axios.post('/api/com_address', { updatedClientIDFormData });
            if (response.status === 200) {
                handleMessages('success', 'Address updated successfully.');
                return "go to next step";
            } else {
                handleMessages('error', 'Address not updated.');
                return "address not updated";
            }
        } catch (error) {
            console.error('SingUp error:', error);
            handleMessages('error', 'An error occurred.');
            return "error occurred";
        }finally{
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if country is empty, return error
        if (!formData.country) {
            setCountryError(true);
            setErrorMessage('Country is required.');
            return "country is required";
        }
        setCountryError(false);
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');
        const updatedFormData = {
            ...formData,
            org: auth.user,
            user_id: auth.user_id
        };
        try {
            const response = await axios.post('/api/com_address', { updatedFormData });
            if (response.status === 200) {
                handleMessages('success', 'Address updated successfully.');
                return "go to next step";
            } else {
                handleMessages('error', 'Address not updated.');
                return "address not updated";
            }
        } catch (error) {
            console.error('SingUp error:', error);
            handleMessages('error', 'An error occurred.');
            return "error occurred";
        }finally{
            setLoading(false);
        }
    };

    const handleDBSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');
        const updatedDBformData = {
            ...DBformData,
            org: auth.user,
            user_id: auth.user_id
        };
        
        try {
            setLoading(true);
            const response = await axios.post('/api/com_address', { updatedDBformData });

            if (response && response.status === 200) {
                handleMessages('success', 'Database connected successful.');
                return "go to next step";
            } else {
                console.error('Test Error', response);
                handleMessages('error', 'Database connected failed.');
            }
            
        } catch (error) {
            // Handle login error
            console.error('Login error:', error);
            handleMessages('error', 'An error occurred during the test connection.');
        }finally{
            setLoading(false);
        }
    };

    const handleNextStep = async (event) => {
        if (activeStep === 2) {
            const result = await handleSubmit(event);
            // Logic to go to the next step
            if (result === "go to next step") {
                setActiveStep(prevStep => prevStep + 1);
            }
        }else if (activeStep === 3) {
            const result = await handleClientIdSubmit(event);
            // Logic to go to the next step
            if (result === "go to next step") {
                setActiveStep(prevStep => prevStep + 1);
            }
        }else if (activeStep === 4) {
            const result = await handleDBSubmit(event);
            // Logic to go to the next step
            if (result === "go to next step") {
                setActiveStep(prevStep => prevStep + 1);
            }
        } else if(activeStep === 5) {
            navigate('/home');
        }
         else {
            // Logic to go to the next step
            setActiveStep(prevStep => prevStep + 1);
        }
    };
    // const handlePreviousStep = () => {
    //     setActiveStep(prevStep => (prevStep > 1 ? prevStep - 1 : prevStep));
    // };

    const renderStepContent = () => {
        switch (activeStep) {
            case 1:
                return (
                    <div>
                        <h6 style={{fontWeight:'600'}}>Configure the database, admin email, and billing address for this account.</h6>
                        <p>We'll guide you through a few steps.</p>
                        <br/>
                        <p> <span style={{fontWeight:'600'}} >
                            1. Update Company Information </span>
                            <br/>
                            Add your company address for billing purposes.
                        </p>
                        <br/>
                        <p> <span style={{fontWeight:'600'}} >
                            2. Add Client Identification </span>
                            <br/>
                            While login the Automiz desktop app, you need to provide the client identification. 
                        </p>
                        <br/>
                        <p> <span style={{fontWeight:'600'}} >
                            3. Connect Your MySQL Database (RDS) to Automiz</span>
                            <br/>
                            All datas are stored on this database.<br/>As of now Automiz will support the MySql database only.
                        </p>
                        {/* <p> <span style={{fontWeight:'600'}} 
                            >3. Connect your MySql DB (RDS) to Automiz</span>
                            <br/>
                            Return to the website that hosts your domain and make updates to route email with Microsoft 365.
                        </p> */}
                        {/* <Button variant="primary" size='sm' style={{ width: '120px' }} onClick={handleNextStep}>Continue</Button>  */}
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h6 style={{fontWeight:'600'}}>Update Company Information</h6>
                        <p>For the billing purposes</p>
                        {/* <Button variant="primary me-2" size='sm' style={{ width: '120px' }} onClick={handleNextStep}>Continue</Button>
                        <Button variant="outline-secondary" size='sm' style={{ width: '120px' }} onClick={handlePreviousStep}>Back</Button> */}

                        <Form id='addressForm' onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <input
                                    style={{fontSize:'14px'}}
                                    type="text"
                                    name="address1"
                                    className="form-control"
                                    placeholder="Address Line 1"
                                    value={formData.address1}
                                    onChange={handleChange}
                                    autoComplete="address-line1"
                                />
                            </div>
                            <div className="mb-2">
                                <input
                                    style={{fontSize:'14px'}}
                                    type="text"
                                    name="address2"
                                    className="form-control"
                                    placeholder="Address Line 2"
                                    value={formData.address2}
                                    onChange={handleChange}
                                    autoComplete="address-line2"
                                />
                            </div>
                            <div className="mb-2">
                                <input
                                    style={{fontSize:'14px'}}
                                    type="text"
                                    name="zipCode"
                                    className="form-control"
                                    placeholder="ZIP / Postal Code"
                                    value={formData.zipCode}
                                    onChange={handleChange}
                                    autoComplete="postal-code"
                                />
                            </div>
                            <div className="mb-2">
                                <input
                                    style={{fontSize:'14px'}}
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    placeholder="City"
                                    value={formData.city}
                                    onChange={handleChange}
                                    autoComplete="address-level2"
                                />
                            </div>
                            <div className="mb-2">
                                <input
                                    style={{fontSize:'14px', borderColor: countryError ? 'red' : ''}}
                                    type="text"
                                    name="country"
                                    className="form-control"
                                    placeholder="Country"
                                    value={formData.country}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-2">
                                <input
                                    style={{fontSize:'14px'}}
                                    type="text"
                                    name="phone"
                                    className="form-control"
                                    placeholder="Phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    autoComplete="tel"
                                />
                            </div>
                        </Form>
                    </div>
                );
            case 3:
                    return (
                        <div>
                            <h6 style={{fontWeight:'600'}}>Add Client Identification</h6>
                            <p>While login the Automiz desktop app, you need to provide the client identification.</p>
                            <Form id='clientIdForm' onSubmit={handleClientIdSubmit}>
                                <div className="mb-2">
                                    <input
                                        style={{fontSize:'14px', borderColor: clientIDError ? 'red' : ''}}
                                        type="text"
                                        name="clientId"
                                        className="form-control"
                                        placeholder="Client ID"
                                        value={ClientIdformData.clientId}
                                        onChange={handleClientIdChange}
                                        autoComplete="client-id"
                                    />
                                </div>
                            </Form>
                        </div>
                    );
            case 4:
                return (
                    <div>
                        <h6 style={{fontWeight:'600'}}>Connect the MySQL Database</h6>
                        <p>All data will be stored in this database.</p>
                        <Form id="dbForm" onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="serverHost"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="Server / Host"
                                    value={DBformData.serverHost} 
                                    autoComplete="server"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="port"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="Port (default is 3306)" 
                                    value={DBformData.port}
                                    autoComplete="port"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="userName"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="User Name" 
                                    value={DBformData.userName}
                                    autoComplete="DBusername"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="password"  
                                    name="DBpassword"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="Password" 
                                    value={DBformData.DBpassword}
                                    autoComplete="DBpassword"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="dbName"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="Database Name"
                                    value={DBformData.dbName}
                                    autoComplete="DBname" 
                                />
                            </div>
                        </Form>
                    
                        {/* <h6 style={{fontWeight:'600'}}>(OR)</h6>
                        <p>Create inbuild database for the testing.</p>
                        <Form id="dbForm" onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="serverHost"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="Server / Host"
                                    value={DBformData.serverHost} 
                                    autoComplete="server"
                                />
                            </div>
                        </Form> */}
                    </div>
                );
            // Add more cases for additional steps
            default:
                return (
                    <div>
                         <h6 style={{fontWeight:'600'}}>Your account ready to use Automiz</h6>
                         <ul>
                            <li>Learn more about Automiz with <a href='/'>Learning Center</a></li>
                            <li>Create support case with  <a href='/'>Service Center</a></li>
                            <li>Connect us with our <a href='/'>Instant Support Team</a></li>
                         </ul>
                         
                         <p style={{fontSize:'12px',fontWeight:'600'}}>Quick Links</p>
                         <ul>
                             <li>Learn more about using Automiz.</li>
                             <li>How to download and install the Automiz app?</li>
                             <li>How to install the Automiz plugins on Illustrator?</li>  
                             <li>How to configure the SMTP mail server?</li>
                             <li>How to create first workflow?</li>
                             <li>How to create and use Job Parametes structure?</li>
                        </ul>
                    </div>
                );
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleClientIdChange = (e) => {
        const { name, value, type, checked } = e.target;
        setClientIdFormData({
            ...ClientIdformData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleDBChange = (e) => {
        // const { name, value, type, checked } = e.target;
        const { name, value} = e.target;
        setDBFormData({
            ...DBformData,
            [name]: value,
        });
    };

    const getStepClass = (step) => {
        if (step < activeStep) {
            return 'complete-step';
        } else if (step === activeStep) {
            return 'active-step';
        } else {
            return '';
        }
    };

    const getStepImage = (step) => {
        if (step === activeStep) {
            return '/icons/nopix.png';
        } else if (step < activeStep) {
            return '/icons/Done.png';
        } else {
            return '/icons/white_round.png';
        }
    };

    const getFontWeight = (step) => {
        return step === activeStep ? 'bold' : 'normal';
    };

    return (    
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
        <div style={{ height:'93%' }}>
            {/* Top Bar */}
            <Navbar expand="lg"  style={{ margin:'0 8px' }}>
                <Col xs={8}>
                    <Navbar.Brand href="/">
                        <img
                            src="automiz_logo.svg" // Replace with your PNG logo path
                            alt="Logo"
                            style={{ width: '110px', marginTop:'-5px'}} // Adjust dimensions as needed
                        />
                    </Navbar.Brand>
                </Col>
                <Col xs={4}>
                    <Nav className="ml-auto"  style={{float:'right'}}>

                            <Button variant="outline-secondary" onClick={handleProfileClick} className="m-1">
                                <img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} />
                            </Button>
                            <Modal show={showProfileModal} onHide={handleProfileClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title> <p style={{fontSize:"15px"}}><img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} /> {auth.user}</p></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div className="dropdown-menu position-static d-flex flex-column flex-lg-row align-items-stretch justify-content-start p-3 rounded-3 shadow-lg" data-bs-theme="light">
                                    <nav className="col-lg-8">
                                    <ul className="list-unstyled d-flex flex-column gap-2">
                                        <li>
                                        <a href="/" className="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg className="bi" width="24" height="24">1</svg>
                                            <div>
                                            <strong className="d-block">Main product</strong>
                                            <small>Take a tour through the product</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" className="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg className="bi" width="24" height="24">2</svg>
                                            <div>
                                            <strong className="d-block">Another product</strong>
                                            <small>Explore this other product we offer</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" className="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg className="bi" width="24" height="24">3</svg>
                                            <div>
                                            <strong className="d-block">Support</strong>
                                            <small>Get help from our support crew</small>
                                            </div>
                                        </a>
                                        </li>
                                    </ul>
                                    </nav>
                                    <div className="d-none d-lg-block vr mx-4 opacity-10">&nbsp;</div>
                                    <hr className="d-lg-none"/>
                                    <div className="col-lg-auto pe-3">
                                    <nav>
                                        <ul className="d-flex flex-column gap-2 list-unstyled small">
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Documentation</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Use cases</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">API status</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Partners</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Resources</a></li>
                                        </ul>
                                    </nav>
                                    </div>
                                </div>
                                </Modal.Body>
                                {/* <Modal.Footer>
                                    <Button variant="secondary" onClick={handleProfileClose}>
                                        Close
                                    </Button>
                                </Modal.Footer> */}
                            </Modal>

                        <Button variant="secondary" onClick={handleLogout} className="m-1">
                            Logout
                        </Button>
                    </Nav>
                </Col>
            </Navbar>
            <Row className=' justify-content-center' style={{padding:'0', margin:'0', height:'98%'}}>
                <Col xs={'8'} sm={'8'} lg={'10'}>        
                    <div style={{ borderRadius: '12px', border: '1px solid #ccc', height:'99%' }}>
                        <div className="row" style={{padding:'60px'}}>
                            <Col xs={'12'} sm={'12'} className='p-3' style={{borderBottom:' 1px solid #ececec'}}>
                                <h6>Hi  {auth.user}, Configure your account</h6>
                            </Col>

                            <Col  xs={'3'} sm={'3'} className='p-4' style={{borderRight:' 1px solid #ececec'}}>
                                <div style={{position:'relative', width:'100%'}}>   
                                    <ul id="progressbar" className="text-center" style={{float:'left'}}>
                                    <li className={`step1 ${getStepClass(1)}`}>
                                        <img src={getStepImage(1)} alt="Logo" />
                                    </li>
                                    <li className={`step2 ${getStepClass(2)}`}>
                                        <img src={getStepImage(2)} alt="Logo" />
                                    </li>
                                    <li className={`step3 ${getStepClass(3)}`}>
                                        <img src={getStepImage(3)} alt="Logo" />
                                    </li>
                                    <li className={`step4 ${getStepClass(4)}`}>
                                        <img src={getStepImage(4)} alt="Logo" />
                                    </li>
                                    <li className={`step4 ${getStepClass(5)}`}>
                                        <img src={getStepImage(5)} alt="Logo" />
                                    </li>
                                    </ul>
                                    <div>
                                    <h6 style={{ margin: '5px 0 0 59px', padding: '8px 0 0 10px', fontSize: '13px', fontWeight: getFontWeight(1) }}>Overview</h6>
                                    <h6 style={{ margin: '5px 0 0 59px', padding: '45px 0 0 10px', fontSize: '13px', fontWeight: getFontWeight(2) }}>Update Company Info</h6>
                                    <h6 style={{ margin: '5px 0 0 59px', padding: '46px 0 0 10px', fontSize: '13px', fontWeight: getFontWeight(3) }}>Add Client Identification</h6>
                                    <h6 style={{ margin: '5px 0 0 59px', padding: '46px 0 0 10px', fontSize: '13px', fontWeight: getFontWeight(4) }}>Connect the Database</h6>
                                    <h6 style={{ margin: '5px 0 0 59px', padding: '47px 0 0 10px', fontSize: '13px', fontWeight: getFontWeight(5) }}>Finish</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col  xs={'7'} sm={'7'} className='p-4' style={{height:'410px'}}>
                                <div className="text-start">
                                {loading ? (
                                    <Col className="col-md-4 p-5">
                                        <div className="text-center" style={{ marginTop:'calc(100%/4)' }}>
                                            <img src="spinner.gif" alt="Loading..." />
                                        </div>
                                    </Col>
                                ) : (   
                                    renderStepContent()
                                )}
                                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                </div>
                            </Col>
                            <Col xs={'3'} sm={'3'} style={{borderTop:' 1px solid #ececec'}}>
                            </Col>
                            <Col xs={'9'} sm={'9'} className='p-4' style={{borderTop:' 1px solid #ececec'}}>
                            
                                {!loading && activeStep === 1 && (
                                    <Button variant="primary me-3" size='sm' style={{ width: '120px' }} onClick={handleNextStep}>Continue</Button>
                                )}

                                {!loading && (activeStep === 2 || activeStep === 3 || activeStep === 4) && (
                                    <>
                                        <Button variant="primary me-3" size='sm' style={{ width: '120px' }} onClick={handleNextStep}>Continue</Button>
                                        {/* <Button variant="outline-secondary" size='sm' style={{ width: '120px' }} onClick={handlePreviousStep}>Back</Button> */}
                                    </>
                                )}

                                {!loading && activeStep === 5 && (
                                    <Button variant="primary me-3" size='sm' style={{ width: '180px' }} onClick={handleNextStep}>Go to Home Page</Button>
                                )}
                            </Col>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        </>
    );
};
export default SetupPage;
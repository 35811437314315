import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { CloseButton, Col } from 'react-bootstrap';

//Modal.setAppElement('#root');

const ParamsModal = ({ isOpen, onRequestClose, onSelect }) => {
  const [paramsData, setParamsData] = useState({});

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/Params', {
        params: { paramsName: 'v1' }
      });

      if (response && response.data && response.data.params) {
        const parsedData = JSON.parse(response.data.params);
        setParamsData(parsedData);
      } else {
        console.error('Invalid response structure:', response);
      }
    } catch (error) {
      console.error('Error fetching params data:', error);
    }
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    onSelect(selectedValue);
  };

  const renderOptions = (data, parentKey = '') => {
    return Object.keys(data).map((key) => {
      const value = data[key];
      const optionLabel = parentKey ? `${parentKey}->${key}` : key;
      const optionValue = parentKey ? `[${parentKey}.${key}]` : key;

      if (typeof value === 'object' && value !== null) {
        return renderOptions(value, optionLabel);
      } else {
        return (
          <option key={optionLabel} value={optionValue}>
            {optionLabel}
          </option>
        );
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Parameters"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
      title="Parameters Modal"
    >
      <div className="container">
        <div className="row">
          <Col xs={10}>
            <h5>Job Parameters</h5>
          </Col>
          <Col  xs={1}>
            <CloseButton size="sm" onClick={onRequestClose}></CloseButton>
          </Col>
        </div>
        <div className="row">
          <select onChange={handleSelectChange}>
            <option value="">Select a parameter</option>
            {renderOptions(paramsData)}
          </select>
        </div>
        <div className="row mt-2">
          
        </div>
      </div>

    </Modal>
   
  );
};

export default ParamsModal;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { Navbar, Nav, Button, Row, Col, Modal, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';

const HomePage = () => {
    const { auth, logout } = useAuth(); // Destructure auth from useAuth hook
    const navigate = useNavigate();
    const [showProfileModal, setShowProfileModal] = useState(false);

    const handleProfileClick = () => {
        setShowProfileModal(true);
    };

    const handleProfileClose = () => {
        setShowProfileModal(false);
    };
    
    const handleLogout = () => {
        logout(); // Call the logout function from the context
        navigate('/'); // Redirect to the login page
    };

    useEffect(() => { // Step 2: Fetch data
        if (!auth.user) {
            // Redirect the user to the login page if not authenticated
            navigate('/');
        }
    }, [auth, navigate]);

    if (!auth.user) {
        // If not authenticated, return null or loading spinner while redirecting
        return null;
    }

    return (    
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
        <div style={{ height:'93%' }}>
            {/* Top Bar */}
            <Navbar expand="lg"  style={{ margin:'0 8px' }}>
                <Col xs={8}>
                    {/* Navbar.Brand for the left side (PNG logo) */}
                    <Navbar.Brand href="/">
                        <img
                            src="automiz_logo.svg" // Replace with your PNG logo path
                            alt="Logo"
                            style={{ width: '110px', marginTop:'-5px'}} // Adjust dimensions as needed
                        />
                    </Navbar.Brand>
                </Col>
                <Col xs={4}>
                    {/* Nav with ml-auto className to align buttons to the right */}
                    <Nav className="ml-auto"  style={{float:'right'}}>
                        {/* <Button variant="primary" onClick={handleSaveWorkflow} className="m-2">
                            Save Workflow
                        </Button> */}

                            <Button variant="outline-secondary" onClick={handleProfileClick} className="m-1">
                                <img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} />
                            </Button>
                            <Modal show={showProfileModal} onHide={handleProfileClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title> <p style={{fontSize:"15px"}}><img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} /> {auth.user}</p></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div className="dropdown-menu position-static d-flex flex-column flex-lg-row align-items-stretch justify-content-start p-3 rounded-3 shadow-lg" data-bs-theme="light">
                                    <nav className="col-lg-8">
                                    <ul className="list-unstyled d-flex flex-column gap-2">
                                        <li>
                                        <a href="/" className="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg className="bi" width="24" height="24">1</svg>
                                            <div>
                                            <strong className="d-block">Main product</strong>
                                            <small>Take a tour through the product</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" className="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg className="bi" width="24" height="24">2</svg>
                                            <div>
                                            <strong className="d-block">Another product</strong>
                                            <small>Explore this other product we offer</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" className="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg className="bi" width="24" height="24">3</svg>
                                            <div>
                                            <strong className="d-block">Support</strong>
                                            <small>Get help from our support crew</small>
                                            </div>
                                        </a>
                                        </li>
                                    </ul>
                                    </nav>
                                    <div className="d-none d-lg-block vr mx-4 opacity-10">&nbsp;</div>
                                    <hr className="d-lg-none"/>
                                    <div className="col-lg-auto pe-3">
                                    <nav>
                                        <ul className="d-flex flex-column gap-2 list-unstyled small">
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Documentation</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Use cases</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">API status</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Partners</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Resources</a></li>
                                        </ul>
                                    </nav>
                                    </div>
                                </div>
                                </Modal.Body>
                                {/* <Modal.Footer>
                                    <Button variant="secondary" onClick={handleProfileClose}>
                                        Close
                                    </Button>
                                </Modal.Footer> */}
                            </Modal>

                        <Button variant="secondary" onClick={handleLogout} className="m-1">
                            Logout
                        </Button>
                    </Nav>
                </Col>
            </Navbar>
            <Row style={{padding:'0', margin:'0', height:'98%'}}>
                <Col xs={'12'} sm={'1'} style={{paddingRight:'0'}}>
                {/* <div  style={{ height: '100vh', padding: '0 10px', margin:'0', width:'5%' }}> */}
                    <Nav className="flex-column">
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}} onClick={() => navigate('/home')} active>
                            <img src="/icons/Home.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Home
                        </Button>
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}} onClick={() => navigate('/workflows')}>
                            <img src="/icons/Flow.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Workflows
                        </Button>
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}}>
                            <img src="/icons/Check.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Validations
                        </Button>
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}} onClick={() => navigate('/configuration')}>
                            <img src="/icons/Config.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Configuration
                        </Button>

                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}}>
                            <img src="/icons/Projects.png" alt="Home" style={{ width: '25px', height: '25px', marginBottom:'3px' }} />
                            <br/>
                            Projects
                        </Button>
                        
                    </Nav>
                {/* </div> */}
                </Col>
                <Col xs={'12'} sm={'11'}>        
                    <div style={{ borderRadius: '12px', border: '1px solid #ccc', height:'99%' }}>
                        <div className="row" style={{padding:'60px'}}>
                            <div className="col">
                                <Card style={{ backgroundImage: "linear-gradient(white, whitesmoke)", cursor:'pointer' }} onClick={() => navigate('/params')}  >
                                    {/* <Card.Img variant="top" src="/imgs/neon-flying-social-media-notification-1.png" /> */}
                                    <Card.Body>
                                        <Card.Title>
                                            <img src="/icons/params.png" alt="Home" style={{ width: '25px', height: '25px', margin:'0 5px 3px 0' }} />
                                            {' '}
                                            Job Parameters
                                        </Card.Title>
                                        <Card.Text style={{fontSize:'13px'}}>
                                        Make customized public parameters.
                                    
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col">
                            <Card style={{ backgroundImage: "linear-gradient(white, whitesmoke)", cursor:'pointer' }} onClick={() => navigate('/dbconfig')}  >
                                    {/* <Card.Img variant="top" src="/imgs/neon-flying-social-media-notification-1.png" /> */}
                                    <Card.Body>
                                        <Card.Title>
                                            <img src="/icons/Database.png" alt="Home" style={{ width: '25px', height: '25px', margin:'0 5px 3px 0' }} />
                                            {' '}
                                            Database Configuration
                                        </Card.Title>
                                        <Card.Text style={{fontSize:'13px'}}>
                                        Make customized public parameters.
                                    
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col">
                            {/* Column */}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        </>
    );
};

export default HomePage;
import React, { memo, useState, useEffect } from 'react';
import { useReactFlow } from 'reactflow';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

function NoteNode({ id, data }) {
  const [note, setNote] = useState(data?.note || '');
  const [noteTitle, setNoteTitle] = useState(data?.noteTitle || 'Note');
  const [dimensions, setDimensions] = useState({
    width: data?.width || 200,
    height: data?.height || 60,
  });
  const { setNodes } = useReactFlow();

  useEffect(() => {
    if (data) {
      setNote(data.note || '');
      setDimensions({
        width: data.width || 200,
        height: data.height || 60,
      });
    }
  }, [data]);

  const handleNoteTitleChange = (event) => {
    const newNoteTitle = event.target.value;
    setNoteTitle(newNoteTitle);

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              noteTitle: newNoteTitle,
            },
          };
        }
        return node;
      })
    );
  };

  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    setNote(newNote);

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              note: newNote,
            },
          };
        }
        return node;
      })
    );
  };

  const handleResizeStop = (event, { size }) => {
    const { width, height } = size;
    setDimensions({ width, height });

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              width,
              height,
            },
          };
        }
        return node;
      })
    );
  };

  return (
    <div className="react-flow__node-notes">
      <div className="notes-node__header">
        <strong>
            <input 
                type='text'  
                style={{ width: '100%', height: '100%', border:'none', background: 'transparent' }}
                value={noteTitle}
                onChange={handleNoteTitleChange}
            />
        </strong>
      </div>
      <ResizableBox
        className="notes-node__body nodrag"
        width={dimensions.width}
        height={dimensions.height}
        minConstraints={[200, 60]}
        maxConstraints={[200, 500]}
        resizeHandles={['se']}
        onResizeStop={handleResizeStop}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <textarea
            style={{ width: '100%', height: '100%', border:'none', background: 'transparent' }}
            value={note}
            onChange={handleNoteChange}
          />
        </div>
      </ResizableBox>
    </div>
  );
}

export default memo(NoteNode);
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './context/AuthContext';
import { Navbar, Nav, Button, Row, Col, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';

const WorkflowsPage = () => {

    const [loading, setLoading] = useState(false);
    const [workflows, setWorkflows] = useState([]); // Step 1: Initialize state
    const { auth, logout } = useAuth(); // Destructure auth from useAuth hook
    const navigate = useNavigate();
    const [showProfileModal, setShowProfileModal] = useState(false);

    const handleProfileClick = () => {
        setShowProfileModal(true);
    };

    const handleProfileClose = () => {
        setShowProfileModal(false);
    };
    
    const editWorkflow = (raw_data, id) => {
        var jsonData = JSON.parse(raw_data);
        //console.log('Editing workflow with ID:', jsonData.nodes);
        // navigate(`/workflow/${id}`);
        navigate(`/workflow`, { state: { initialNodes: jsonData.nodes, initialEdges: jsonData.edges } });
    };


    const handleLogout = () => {
        logout(); // Call the logout function from the context
        navigate('/'); // Redirect to the login page
    };

    useEffect(() => { // Step 2: Fetch data

        if (!auth.user) {
            // Redirect the user to the login page if not authenticated
            navigate('/');
        }

        const fetchWorkflows = async () => {
            try {
                setLoading(true);
                // const response1 = await axios.get('/api/workflows_old');
                // console.log('Node data sent successfully:', response1.data);
                const response = await axios.post('/api/workflows', {'org': auth.user, 'user_id': auth.user_id} , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log('Node data sent successfully:', response.data);
                setWorkflows(response.data); // Assuming the response data is the array of workflows
            } catch (error) {
                console.error('Error fetching workflows:', error);
            }finally{
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, [auth, navigate]);

    if (!auth.user) {
        // If not authenticated, return null or loading spinner while redirecting
        return null;
    }

    return (    
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
        <div style={{ height:'93%' }}>
            {/* Top Bar */}
            <Navbar expand="lg"  style={{ margin:'0 8px' }}>
                <Col xs={8}>
                    {/* Navbar.Brand for the left side (PNG logo) */}
                    <Navbar.Brand href="/">
                        <img
                            src="automiz_logo.svg" // Replace with your PNG logo path
                            alt="Logo"
                            style={{ width: '110px', marginTop:'-5px'}} // Adjust dimensions as needed
                        />
                    </Navbar.Brand>
                </Col>
                <Col xs={4}>
                    {/* Nav with ml-auto class to align buttons to the right */}
                    <Nav className="ml-auto"  style={{float:'right'}}>
                        {/* <Button variant="primary" onClick={handleSaveWorkflow} className="m-2">
                            Save Workflow
                        </Button> */}

                            <Button variant="outline-secondary" onClick={handleProfileClick} className="m-1">
                                <img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} />
                            </Button>
                            <Modal show={showProfileModal} onHide={handleProfileClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title> <p style={{fontSize:"15px"}}><img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} /> {auth.user}</p></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div class="dropdown-menu position-static d-flex flex-column flex-lg-row align-items-stretch justify-content-start p-3 rounded-3 shadow-lg" data-bs-theme="light">
                                    <nav class="col-lg-8">
                                    <ul class="list-unstyled d-flex flex-column gap-2">
                                        <li>
                                        <a href="/" class="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg class="bi" width="24" height="24">1</svg>
                                            <div>
                                            <strong class="d-block">Main product</strong>
                                            <small>Take a tour through the product</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" class="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg class="bi" width="24" height="24">2</svg>
                                            <div>
                                            <strong class="d-block">Another product</strong>
                                            <small>Explore this other product we offer</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" class="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg class="bi" width="24" height="24">3</svg>
                                            <div>
                                            <strong class="d-block">Support</strong>
                                            <small>Get help from our support crew</small>
                                            </div>
                                        </a>
                                        </li>
                                    </ul>
                                    </nav>
                                    <div class="d-none d-lg-block vr mx-4 opacity-10">&nbsp;</div>
                                    <hr class="d-lg-none"/>
                                    <div class="col-lg-auto pe-3">
                                    <nav>
                                        <ul class="d-flex flex-column gap-2 list-unstyled small">
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Documentation</a></li>
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Use cases</a></li>
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">API status</a></li>
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Partners</a></li>
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Resources</a></li>
                                        </ul>
                                    </nav>
                                    </div>
                                </div>
                                </Modal.Body>
                                {/* <Modal.Footer>
                                    <Button variant="secondary" onClick={handleProfileClose}>
                                        Close
                                    </Button>
                                </Modal.Footer> */}
                            </Modal>

                        <Button variant="secondary" onClick={handleLogout} className="m-1">
                            Logout
                        </Button>
                    </Nav>
                </Col>
            </Navbar>
            <Row style={{padding:'0', margin:'0', height:'98%'}}>
                <Col xs={'12'} sm={'1'} style={{paddingRight:'0'}}>
                {/* <div  style={{ height: '100vh', padding: '0 10px', margin:'0', width:'5%' }}> */}
                    <Nav className="flex-column">
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}} onClick={() => navigate('/home')}>
                            <img src="/icons/Home.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Home
                        </Button>
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}} onClick={() => navigate('/workflows')} active>
                            <img src="/icons/Flow.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Workflows
                        </Button>
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}}>
                            <img src="/icons/Check.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Validations
                        </Button>
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}}>
                            <img src="/icons/Config.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Configuration
                        </Button>

                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}}>
                            <img src="/icons/Projects.png" alt="Home" style={{ width: '25px', height: '25px', marginBottom:'3px' }} />
                            <br/>
                            Projects
                        </Button>
                        
                    </Nav>
                {/* </div> */}
                </Col>
                <Col xs={'12'} sm={'11'}>        
                    <div style={{ borderRadius: '12px', border: '1px solid #ccc', height:'99%' }}>
                        <div className="d-flex justify-content-between align-items-center p-2 border-bottom">
                            <h2 style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft:'4px' }}>Workflows</h2>
                                        
                            <Button variant="primary" size="sm" onClick={() => navigate('/workflow')}>    
                                <img src="/icons/Create3.png" alt="Home" style={{ width: '15px', height: '15px' }} />
                                &nbsp;New
                            </Button>   
                        </div>
                        <div className='frame' style={{height:'80%'}}>      
                        {/* <div style={{ height: '400px', overflowY: 'scroll' }}> */}
                            {loading ? (
                                <Col xs={'12'} sm={'12'} className="p-1">
                                    <div className="text-center" style={{ marginTop:'calc(100%/5)' }}>
                                        <img src="spinner.gif" alt="Loading..." style={{ width:'50px' }}/>
                                    </div>
                                </Col>
                            ) : ( 
                                <ul className="list-group list-group-flush m-2" >
                                    {workflows.map(workflow => (
                                        <li key={workflow.id} className="list-group-item d-flex align-items-left">
                                            <Button variant="light" style={{ fontSize: "13px", color: "#000", marginRight: '3px' }} onClick={() => editWorkflow(workflow.raw_data, workflow.id)}>
                                                <img src="/icons/Edit.png" alt="Home" style={{ width: '12px', height: '12px' }} />
                                                &nbsp;&nbsp;
                                                {workflow.flow_name}
                                            </Button>
                                            
                                            {/* <div>
                                                <Button size='sm' variant="primary" className="me-2" onClick={() => editWorkflow(workflow.raw_data, workflow.id)}>Edit</Button>
                                                <Button size='sm' variant="warning" className="me-2" onClick={() => publishWorkflow(workflow.id)}>Publish</Button>
                                                <Button size='sm' variant="danger" className="me-2" onClick={() => softDeleteWorkflow(workflow.id)}>Soft Delete</Button>
                                            </div> */}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        </>
    );
};

// const editWorkflow = (id) => {
//     console.log('Editing workflow with ID:', id);
//     // Implementation for editing a workflow
// };

// const publishWorkflow = (id) => {
//     console.log('Publishing workflow with ID:', id);
//     // Implementation for publishing a workflow
// };

// const softDeleteWorkflow = (id) => {
//     console.log('Soft deleting workflow with ID:', id);
//     // Implementation for soft deleting a workflow
// };



export default WorkflowsPage;
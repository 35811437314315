import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    Background,
    Position,
    useNodesState,
    useEdgesState,
} from 'reactflow';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import axios from 'axios';
import { Navbar, Nav, Button, Row, Col, ListGroup, Modal, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';

import './style.css';
import './moduls/Notes.css';
import './moduls/CustomNode.css';
// import './moduls/LayersNode.css';
import './moduls/LayerNode.css';
import './moduls/RouterNode.css';
import './moduls/DocumentNode.css';
import './moduls/Script.css';
import './moduls/Warning.css';
import './moduls/Error.css';

import { nodes as initialNodes, edges as initialEdges } from './initial-elements';
import { available_nodes } from './available-nodes';
import NotesNode from './moduls/Notes';
import CustomNode from './moduls/CustomNode';
// import LayersNode from './moduls/LayersNode';
import LayerNode from './moduls/LayerNode';
import RouterNode from './moduls/RouterNode';
import DocumentNode from './moduls/DocumentNode';
import Script from './moduls/Script';
import Warning from './moduls/Warning';
import Error from './moduls/Error';

const minimapStyle = {
    height: 120,
};

const OverviewFlow = () => {

    const [showModal, setShowModal] = useState(false);
    const [workflowName, setWorkflowName] = useState('');

    // Memoize nodeTypes to avoid recreating the object on each render
    const nodeTypes = useMemo(() => ({
        notes: NotesNode,
        custom: CustomNode,
        // layers: LayersNode,
        layer: LayerNode,
        router: RouterNode,
        document: DocumentNode,
        script: Script,
        warning: Warning,
        error: Error,
    }), []);

    const location = useLocation();

    // Define state for currentNode
    const [currentNode, setCurrentNode] = useState(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(location.state?.initialNodes || initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(location.state?.initialEdges || initialEdges);
    // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const sendNodeData = async (node, workflowName) => {
        console.log('workflow Name:', workflowName);
        console.log('Sending edges data:', edges);
        console.log('Sending nodes data:', nodes);
        try {
            const response = await axios.post('/api/nodes', {'workflowName':workflowName, 'nodes':node, 'edges':edges, 'org': auth.user, 'user_id': auth.user_id} , {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Node data sent successfully:', response.data);
        } catch (error) {
            console.error('Error sending node data:', error);
        }
    };

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

    const onDragStart = (event, node) => {
        event.dataTransfer.setData('nodeType', node.type);
    };

    const onDrop = (event) => {
        event.preventDefault();
        const type = event.dataTransfer.getData('nodeType');
        const { clientX, clientY } = event;
        const id = type + '_' + Math.random();

        // console.log('Dropped node type:', type);
        // console.log('Dropped node position:', { x: clientX, y: clientY });

        let additionalData, additionalClassName, additionalStyle = {};

        if (type === 'input') {
            additionalData = { label: 'Start' };
            additionalClassName = 'circle';
            additionalStyle = { background: '#2572cd', color: 'white' };
            // SourcePositionRight: {Position.Right};
        } 
        else if (type === 'output') {
            additionalData = { label: 'Finish' };
            additionalClassName = 'circle';
            additionalStyle = { background: '#02b51a', color: 'white' };
        } 
        else if (type === 'warning') {
            additionalData = { label: 'Warning' };
            additionalClassName = 'circle';
            //additionalStyle = { background: '#ec0101', color: 'white' };
        } 
        else if (type === 'error') {
            additionalData = { label: 'Error' };
            additionalClassName = 'circle';
            //additionalStyle = { background: '#ec0101', color: 'white' };
        } 
        else if (type === 'custom') {
            additionalData = {
                label: 'Custom', 
                selects: {
                    'handle-0': 'smoothstep',
                    'handle-1': 'smoothstep',
                },
            };
        } 
        else if (type === 'layers') {
            additionalData = {
                label: 'Layers', 
                selects: {
                    'handle-0': 'Remove',
                },
                style: {
                    background: '#706f70',
                    color: 'white',
                    width: 100,
                },
            };
        }
        else if (type === 'layer') {
            additionalData = {
                label: 'Layer', 
                selects: {
                    'handle-0': 'Remove',
                },
                style: {
                    background: '#706f70',
                    color: 'white',
                    width: 100,
                },
            };
        }
        else if (type === 'router') {
            additionalData = {
                label: 'Router', 
                selects: {
                    'handle-0': 'Start_With',
                },
                style: {
                    background: '#706f70',
                    color: 'white',
                    width: 100,
                },
            };
        }
        else if (type === 'document') {
            additionalData = {
                label: 'Document', 
                selects: {
                    'handle-0': 'Start_With',
                },
                style: {
                    background: '#706f70',
                    color: 'white',
                    width: 100,
                },
            };
        }
        else if (type === 'script') {
            additionalData = {
                label: 'Script', 
                selects: {
                    'handle-0': 'Start_With',
                },
                style: {
                    background: '#706f70',
                    color: 'white',
                    width: 100,
                },
            };
        }

        //console.log('Additional data:', additionalData);

        const newNode = {
            id,
            type,
            position: { x: clientX, y: clientY },
            data: additionalData,
            className: additionalClassName,
            style: additionalStyle,
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
        };

        // if notes is not contarin the input node type then only need setNodes
        const inputNode = nodes.filter((node) => node.type === 'input'); 
        if (type !== 'input' || inputNode.length === 0) {
           // Update nodes and currentNode states
            setNodes((prevNodes) => [...prevNodes, newNode]);
            setCurrentNode(newNode);
        }else{
            alert('You can only add one Start function.');
        }
        // Send new node data to Flask API
        // sendNodeData(nodes);
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    const handleSaveWorkflow = () => {        
        setShowModal(true);
    };

    const saveWorkflow = () => {
        // Send the entire list of nodes to the Flask API
        sendNodeData(nodes, workflowName);
        // Close the modal
        setShowModal(false);
    };

    const { auth, logout } = useAuth(); // Destructure auth from useAuth hook
    const navigate = useNavigate();
    const [showProfileModal, setShowProfileModal] = useState(false);

    const handleProfileClick = () => {
        setShowProfileModal(true);
    };

    const handleProfileClose = () => {
        setShowProfileModal(false);
    };

    const handleLogout = () => {
        logout(); // Call the logout function from the context
        navigate('/'); // Redirect to the login page
    };

    // Use useEffect to check if the user is authenticated
    useEffect(() => {
        if (!auth.user) {
            // Redirect the user to the login page if not authenticated
            navigate('/');
        }
    }, [auth, navigate]);

    if (!auth.user) {
        // If not authenticated, return null or loading spinner while redirecting
        return null;
    }

    return (
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
        <div style={{ height:'93%' }}>
            {/* Top Bar */}
            <Navbar expand="lg"  style={{ margin:'0 8px' }}>
                <Col xs={8}>
                    {/* Navbar.Brand for the left side (PNG logo) */}
                    <Navbar.Brand href="/">
                        <img
                            src="automiz_logo.svg" // Replace with your PNG logo path
                            alt="Logo"
                            style={{ width: '110px', marginTop:'-5px'}} // Adjust dimensions as needed
                        />
                    </Navbar.Brand>
                </Col>
                <Col xs={4}>
                    {/* Nav with ml-auto class to align buttons to the right */}
                    <Nav className="ml-auto"  style={{float:'right'}}>
                        <Button variant="primary" onClick={handleSaveWorkflow} className="m-1">
                            Save Workflow
                        </Button>

                        <Button variant="outline-secondary" onClick={handleProfileClick} className="m-1">
                            <img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} />
                        </Button>
                            <Modal show={showProfileModal} onHide={handleProfileClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title> <p style={{fontSize:"15px"}}><img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} /> {auth.user}</p></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div class="dropdown-menu position-static d-flex flex-column flex-lg-row align-items-stretch justify-content-start p-3 rounded-3 shadow-lg" data-bs-theme="light">
                                    <nav class="col-lg-8">
                                    <ul class="list-unstyled d-flex flex-column gap-2">
                                        <li>
                                        <a href="/" class="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg class="bi" width="24" height="24">1</svg>
                                            <div>
                                            <strong class="d-block">Main product</strong>
                                            <small>Take a tour through the product</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" class="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg class="bi" width="24" height="24">2</svg>
                                            <div>
                                            <strong class="d-block">Another product</strong>
                                            <small>Explore this other product we offer</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" class="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg class="bi" width="24" height="24">3</svg>
                                            <div>
                                            <strong class="d-block">Support</strong>
                                            <small>Get help from our support crew</small>
                                            </div>
                                        </a>
                                        </li>
                                    </ul>
                                    </nav>
                                    <div class="d-none d-lg-block vr mx-4 opacity-10">&nbsp;</div>
                                    <hr class="d-lg-none"/>
                                    <div class="col-lg-auto pe-3">
                                    <nav>
                                        <ul class="d-flex flex-column gap-2 list-unstyled small">
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Documentation</a></li>
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Use cases</a></li>
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">API status</a></li>
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Partners</a></li>
                                        <li><a href="/" class="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Resources</a></li>
                                        </ul>
                                    </nav>
                                    </div>
                                </div>
                                </Modal.Body>
                                {/* <Modal.Footer>
                                    <Button variant="secondary" onClick={handleProfileClose}>
                                        Close
                                    </Button>
                                </Modal.Footer> */}
                            </Modal>

                        <Button variant="secondary" onClick={handleLogout} className="m-1">
                            Logout
                        </Button>
                    </Nav>
                </Col>
            </Navbar>
            <Row style={{padding:'0', margin:'0', height:'98%'}}>
                <Col xs={'12'} sm={'1'} style={{paddingRight:'0'}}>
                    <div className='frame' style={{height:'90%', width:'8%'}}>   
                        <ListGroup variant="flush">
                            {available_nodes.map((node) => (
                                <ListGroup.Item
                                    key={node.id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, node)}
                                    style={{ cursor: 'move', padding:"14px 5px" }}
                                >
                                    <img
                                        src={`./icons/${node.data.label}.png`} // Replace with your PNG logo path
                                        alt="Logo"
                                        style={{ width: '20px', height: '20px', margin:'-3px 4px 0 0' }} // Adjust dimensions as needed
                                    />
                                    {node.data.label}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </Col>
                <Col xs={'12'} sm={'11'}  onDrop={onDrop} onDragOver={onDragOver} >        
                    <div style={{ borderRadius: '12px', border: '1px solid #ccc', height:'99%' }}>
                        <div className='frame' style={{height:'90.1%'}}>      
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                fitView
                                attributionPosition="top-right"
                                nodeTypes={nodeTypes}
                            >
                                <MiniMap style={minimapStyle} zoomable pannable />
                                <Controls />
                                <Background color="#aaa" gap={16} />
                            </ReactFlow>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>

        {/* Bootstrap Modal for saving workflow */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Save Workflow</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    saveWorkflow(); // Call your save function
                }}>
                    <Form.Group>
                        <Form.Label>Workflow Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter workflow name"
                            value={workflowName}
                            onChange={(e) => setWorkflowName(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={saveWorkflow}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
</>
    );
};

export default OverviewFlow;

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    // Initialize the auth state and loading state
    const [auth, setAuth] = useState(() => {
        // Retrieve persisted auth state from local storage, if it exists
        const persistedAuth = localStorage.getItem('auth');
        if (persistedAuth) {
            return JSON.parse(persistedAuth);
        }
        return { user: null, loading: false };
    });

    // Save the auth state to local storage whenever it changes
    useEffect(() => {
        localStorage.setItem('auth', JSON.stringify(auth));
    }, [auth]);

    // Login function to update auth state and persist user data
    const login = (Data) => {
        //('user', Data);
        setAuth({ user: Data.org, user_id: Data.user_id, loading: false });
    };

    // Logout function to clear auth state and remove user data from local storage
    const logout = async () => {
        const userData = {
            org: auth.user,
            user_id: auth.user_id
        };
        const response = await axios.post('/api/logout', { userData });
        if (response.status === 200) {
            setAuth({ user: null, loading: false });
            localStorage.removeItem('auth');
        }
       
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

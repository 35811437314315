import React, { memo, useState, useEffect } from 'react';
import { Handle, useReactFlow, Position } from 'reactflow';
import ParamsModal from '../moduls/ParamsModal.jsx';
import RegExModal from '../moduls/RegExModal.jsx';

const options = [
  { value: 'Same_as', label: 'Is Same as' },
  { value: 'Contain', label: 'Contains' },
  { value: 'Start_With', label: 'Start With' },
  { value: 'End_With', label: 'End With' },
];

function Select({ value, handleId, nodeId, onDeleteHandle, onInputChange, inputValues }) {
  const { setNodes } = useReactFlow();

  const onChange = (evt) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              selects: {
                ...node.data.selects,
                [handleId]: evt.target.value,
              },
              inputFieldsData: { ...inputValues },
            },
          };
        }
        return node;
      })
    );
  };

  return (
    <div className="router-node__select">
      <select className="nodrag" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <input 
        type="text"  
        value={inputValues[handleId] || ''} 
        onChange={(e) => onInputChange(handleId, e.target.value)}
      />
      {handleId !== "handle-0" && (
        <button className='removeButton' onClick={() => onDeleteHandle(handleId)}>-</button>
      )}
      <Handle type="source" position={Position.Right} id={handleId} />
    </div>
  );
}

function RouterNode({ id, data }) {
  const { setNodes } = useReactFlow();
  const [handles, setHandles] = useState(Object.keys(data.selects));
  const [inputValues, setInputValues] = useState(data.inputFieldsData || {});

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              inputFieldsData: { ...inputValues },
            },
          };
        }
        return node;
      })
    );
  }, [inputValues, id, setNodes]);

  const handleInputChange = (fieldIndex, inputValue) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [fieldIndex]: inputValue,
    }));
  };

  const handleAddRegex = (regexPattern) => {
    handleInputChange("main_input", regexPattern);
    closeModal("regex")();
  };

  const handleSelect = (selectedValue) => {
    handleInputChange("main_input", selectedValue);
    closeModal("params")();
    setParamModalIsOpen(false);
  };

  const addHandle = () => {
    const newHandleId = `handle-${handles.length}`;
    setHandles((prevHandles) => [...prevHandles, newHandleId]);

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              selects: {
                ...node.data.selects,
                [newHandleId]: options[0].value,
              },
              inputFieldsData: { ...inputValues },
            },
          };
        }
        return node;
      })
    );
  };

  const deleteHandle = (handleId) => {
    // Remove the handle from the state
    setHandles((prevHandles) => prevHandles.filter((id) => id !== handleId));

    // Update the nodes data to remove the handle's entry from both selects and inputFieldsData
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          const updatedSelects = { ...node.data.selects };
          const updatedInputFieldsData = { ...node.data.inputFieldsData };

          // Delete the entries corresponding to the handleId
          delete updatedSelects[handleId];
          delete updatedInputFieldsData[handleId];

          return {
            ...node,
            data: {
              ...node.data,
              selects: updatedSelects,
              inputFieldsData: updatedInputFieldsData,
            },
          };
        }
        return node;
      })
    );

    // Also remove the handle's entry from inputValues state
    setInputValues((prevInputValues) => {
      const newInputValues = { ...prevInputValues };
      delete newInputValues[handleId];
      return newInputValues;
    });
  };

  const [ParamModalIsOpen, setParamModalIsOpen] = useState(false);
  const [RegExModalIsOpen, setRegExModalIsOpen] = useState(false);

  const openModal = (type) => () => {
    if (type === "params") {
      setParamModalIsOpen(true);
    } else if (type === "regex") {
      setRegExModalIsOpen(true);
    }
  };

  const closeModal = (type) => () => {
    if (type === "params") {
      setParamModalIsOpen(false);
    } else if (type === "regex") {
      setRegExModalIsOpen(false);
    }
  };
  
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="left-handle"
        // style={{ top: '25px' }}
      />
      <div className="router-node__header">
        <strong>Router</strong>
        <br />
        <img
          src={`./icons/params.png`} // Adjust the path as needed
          alt="Logo"
          style={{ width: '14px', height: '14px', margin: '0px 4px 0 0', cursor: 'pointer' }} // Adjust dimensions as needed
          onClick={openModal("params")}
        />
        <ParamsModal 
          isOpen={ParamModalIsOpen} 
          onRequestClose={closeModal("params")}
          onSelect={handleSelect} 
        >
          <div>Params Content</div>
        </ParamsModal>

        <input 
          type="text" 
          value={inputValues["main_input"] || ''} 
          onChange={(e) => handleInputChange("main_input", e.target.value)}
          placeholder="Main Input"
          style={{ color: inputValues["main_input"]?.startsWith('[') && inputValues["main_input"]?.endsWith(']') ? '#ce0d0d' : '#700494' }}
        />

        <img
          src={`./icons/regex.png`} // Adjust the path as needed
          alt="Logo"
          style={{ width: '14px', height: '14px', margin: '0 4px', cursor: 'pointer' }} // Adjust dimensions as needed
          onClick={openModal("regex")}
        />
        <RegExModal isOpen={RegExModalIsOpen} onRequestClose={closeModal("regex")} 
          addRegex={handleAddRegex}  >
          <div>Regex Content</div>
        </RegExModal>

      </div>
      <div className="router-node__body">
        {handles.map((handleId) => (
          <Select
            key={handleId}
            nodeId={id}
            value={data.selects[handleId]}
            handleId={handleId}
            onDeleteHandle={deleteHandle}
            onInputChange={handleInputChange}
            inputValues={inputValues}
          />
        ))}
        <button className='addButton' onClick={addHandle}>+</button>
      </div>
    </>
  );
}

export default memo(RouterNode);

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './context/AuthContext';
import { Navbar, Nav, Button, Row, Col, Modal, Form, ListGroup, Tab } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';

const SetupPage = () => {
    const { auth, logout } = useAuth(); // Destructure auth from useAuth hook
    const navigate = useNavigate();
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [activeKey, setActiveKey] = useState('#step1');
    const [loading, setLoading] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [clientIDError, setClientIDError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    
    const [formData, setFormData] = useState({
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        country: '',
        phone: '',
    });

    const [ClientIdformData, setClientIdFormData] = useState({
        clientId: ''
    });

    const [DBformData, setDBFormData] = useState({
        serverHost: '',
        port: '',
        userName: '',
        DBpassword: '',
        dbName: '',
    });

    const [UserformData, setUserformData] = useState({
        userName: '',
        userPassword: '',
        userEmail: '',
    });

    useEffect(() => { 
    }, [auth]);

    if (!auth.user) {
        return null;
    }


    const handleProfileClick = () => {
        setShowProfileModal(true);
    };

    const handleProfileClose = () => {
        setShowProfileModal(false);
    };
    
    const handleLogout = () => {
        logout(); // Call the logout function from the context
        navigate('/'); // Redirect to the login page
    };

    const handleMessages = (type, message) => {
        if (type === 'success') {
            setSuccessMessage(message);
            setTimeout(() => setSuccessMessage(''), 2000); // Clear message after 2 seconds
        } else if (type === 'error') {
            setErrorMessage(message);
            setTimeout(() => setErrorMessage(''), 2000); // Clear message after 2 seconds
        }
    };

    const handleClientIdSubmit = async (e) => {
        e.preventDefault();
        // if country is empty, return error
        console.log('handle Client Id Submit');
        if (!ClientIdformData.clientId) {
            setClientIDError(true);
            setErrorMessage('clientID is required.');
            return "clientID is required";
        }
        setClientIDError(false);
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');
        const updatedClientIDFormData = {
            ...ClientIdformData,
            org: auth.user,
            user_id: auth.user_id
        };
        try {
            const response = await axios.post('/api/com_address', { updatedClientIDFormData });
            if (response.status === 200) {
                handleMessages('success', 'Address updated successfully.');
                return "go to next step";
            } else {
                handleMessages('error', 'Address not updated.');
                return "address not updated";
            }
        } catch (error) {
            console.error('SingUp error:', error);
            handleMessages('error', 'An error occurred.');
            return "error occurred";
        }finally{
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if country is empty, return error
        if (!formData.country) {
            setCountryError(true);
            setErrorMessage('Country is required.');
            return "country is required";
        }
        setCountryError(false);
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');
        const updatedFormData = {
            ...formData,
            org: auth.user,
            user_id: auth.user_id
        };
        try {
            const response = await axios.post('/api/com_address', { updatedFormData });
            if (response.status === 200) {
                handleMessages('success', 'Address updated successfully.');
                return "go to next step";
            } else {
                handleMessages('error', 'Address not updated.');
                return "address not updated";
            }
        } catch (error) {
            console.error('SingUp error:', error);
            handleMessages('error', 'An error occurred.');
            return "error occurred";
        }finally{
            setLoading(false);
        }
    };

    const handleDBSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');
        const updatedDBformData = {
            ...DBformData,
            org: auth.user,
            user_id: auth.user_id
        };
        
        try {
            setLoading(true);
            const response = await axios.post('/api/com_address', { updatedDBformData });

            if (response && response.status === 200) {
                handleMessages('success', 'Database connected successful.');
                return "go to next step";
            } else {
                console.error('Test Error', response);
                handleMessages('error', 'Database connected failed.');
            }
            
        } catch (error) {
            // Handle login error
            console.error('Login error:', error);
            handleMessages('error', 'An error occurred during the connection.');
        }finally{
            setLoading(false);
        }
    };

    const handleUserSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');
        const updatedUserformData = {
            ...UserformData,
            org: auth.user,
            user_id: auth.user_id
        };
        
        try {
            setLoading(true);
            const response = await axios.post('/api/com_address', { updatedUserformData });

            if (response && response.status === 200) {
                handleMessages('success', 'User create or Update successful.');
                return "go to next step";
            } else {
                console.error('Test Error', response);
                handleMessages('error', 'createUpdateUser failed.');
            }
            
        } catch (error) {
            // Handle login error
            console.error('Login error:', error);
            handleMessages('error', 'An error occurred during the connection.');
        }finally{
            setLoading(false);
        }
    };

    const handleNextStep = async (event) => {
        if (activeKey === "#step1") {
            handleUserSubmit(event);
        } else if (activeKey === "#step2") {
            handleSubmit(event);
        }else if (activeKey === "#step3") {
            handleDBSubmit(event);
        }else if (activeKey === "#step4") {
            handleSubmit(event);
        }
    };

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const renderStepContent = () => {
        switch (activeKey) {
            case '#step1':
                return (
                    <div>
                        <h6 style={{fontWeight:'600'}}>Create a new user</h6>
                        <p>to access the Automiz application</p>
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="userName"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleUserChange} 
                                    placeholder="User Name" 
                                    value={UserformData.userName}
                                    autoComplete="DBusername"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="password"  
                                    name="userPassword"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleUserChange} 
                                    placeholder="User Password" 
                                    value={UserformData.userPassword}
                                    autoComplete="user password"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="userEmail"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleUserChange} 
                                    placeholder="User Email"
                                    value={UserformData.userEmail}
                                    autoComplete="User Email" 
                                />
                            </div>
                        </Form>
                    </div>
                );
            case '#step2':
                    return (
                        <div>
                            <h6 style={{fontWeight:'600'}}>Update client identification</h6>
                            <p>While login the Automiz desktop app, you need to provide the client identification.</p>
                            <Form onSubmit={handleClientIdSubmit}>
                                <div className="mb-2">
                                    <input
                                        style={{fontSize:'14px', borderColor: clientIDError ? 'red' : ''}}
                                        type="text"
                                        name="clientId"
                                        className="form-control"
                                        placeholder="Client ID"
                                        value={ClientIdformData.clientId}
                                        onChange={handleClientIdChange}
                                        autoComplete="client-id"
                                    />
                                </div>
                            </Form>
                        </div>
                    );
            case '#step3':
                return (
                    <div>
                        <h6 style={{fontWeight:'600'}}>Update the MySQL database</h6>
                        <p>All data will be stored in this database.</p>
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="serverHost"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="Server / Host"
                                    value={DBformData.serverHost} 
                                    autoComplete="server"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="port"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="Port (default is 3306)" 
                                    value={DBformData.port}
                                    autoComplete="port"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="userName"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="User Name" 
                                    value={DBformData.userName}
                                    autoComplete="DBusername"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="password"  
                                    name="DBpassword"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="Password" 
                                    value={DBformData.DBpassword}
                                    autoComplete="DBpassword"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="dbName"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleDBChange} 
                                    placeholder="Database Name"
                                    value={DBformData.dbName}
                                    autoComplete="DBname" 
                                />
                            </div>
                        </Form>
                    </div>
                );         
            case '#step4':
                    return (
                        <div>
                            <h6 style={{fontWeight:'600'}}>Update company information</h6>
                            <p>For the billing purposes</p>
                            {/* <Button variant="primary me-2" size='sm' style={{ width: '120px' }} onClick={handleNextStep}>Continue</Button>
                            <Button variant="outline-secondary" size='sm' style={{ width: '120px' }} onClick={handlePreviousStep}>Back</Button> */}
    
                            <Form onSubmit={handleSubmit}>
                                <div className="mb-2">
                                    <input
                                        style={{fontSize:'14px'}}
                                        type="text"
                                        name="address1"
                                        className="form-control"
                                        placeholder="Address Line 1"
                                        value={formData.address1}
                                        onChange={handleChange}
                                        autoComplete="address-line1"
                                    />
                                </div>
                                <div className="mb-2">
                                    <input
                                        style={{fontSize:'14px'}}
                                        type="text"
                                        name="address2"
                                        className="form-control"
                                        placeholder="Address Line 2"
                                        value={formData.address2}
                                        onChange={handleChange}
                                        autoComplete="address-line2"
                                    />
                                </div>
                                <div className="mb-2">
                                    <input
                                        style={{fontSize:'14px'}}
                                        type="text"
                                        name="zipCode"
                                        className="form-control"
                                        placeholder="ZIP / Postal Code"
                                        value={formData.zipCode}
                                        onChange={handleChange}
                                        autoComplete="postal-code"
                                    />
                                </div>
                                <div className="mb-2">
                                    <input
                                        style={{fontSize:'14px'}}
                                        type="text"
                                        name="city"
                                        className="form-control"
                                        placeholder="City"
                                        value={formData.city}
                                        onChange={handleChange}
                                        autoComplete="address-level2"
                                    />
                                </div>
                                <div className="mb-2">
                                    <input
                                        style={{fontSize:'14px', borderColor: countryError ? 'red' : ''}}
                                        type="text"
                                        name="country"
                                        className="form-control"
                                        placeholder="Country"
                                        value={formData.country}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-2">
                                    <input
                                        style={{fontSize:'14px'}}
                                        type="text"
                                        name="phone"
                                        className="form-control"
                                        placeholder="Phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        autoComplete="tel"
                                    />
                                </div>
                            </Form>
                        </div>
                    );
            default:
                return (
                    <div>
                        <h6 style={{fontWeight:'600'}}>Create a new user</h6>
                        {/* <p>All data will be stored in this database.</p> */}
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="userName"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleUserChange} 
                                    placeholder="User Name" 
                                    value={UserformData.userName}
                                    autoComplete="DBusername"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="password"  
                                    name="userPassword"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleUserChange} 
                                    placeholder="User Password" 
                                    value={UserformData.userPassword}
                                    autoComplete="user password"
                                />
                            </div>
                            <div className="mb-2">
                                <input 
                                    type="text"  
                                    name="userEmail"
                                    style={{fontSize:'14px'}} 
                                    className="form-control" 
                                    onChange={handleUserChange} 
                                    placeholder="User Email"
                                    value={UserformData.userEmail}
                                    autoComplete="User Email" 
                                />
                            </div>
                        </Form>
                    </div>
                );
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleClientIdChange = (e) => {
        const { name, value, type, checked } = e.target;
        setClientIdFormData({
            ...ClientIdformData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleDBChange = (e) => {
        // const { name, value, type, checked } = e.target;
        const { name, value} = e.target;
        setDBFormData({
            ...DBformData,
            [name]: value,
        });
    };

    const handleUserChange = (e) => {
        // const { name, value, type, checked } = e.target;
        const { name, value} = e.target;
        setUserformData({
            ...UserformData,
            [name]: value,
        });
    };

    return (    
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
        <div style={{ height:'93%' }}>
            {/* Top Bar */}
            <Navbar expand="lg"  style={{ margin:'0 8px' }}>
                <Col xs={8}>
                    <Navbar.Brand href="/">
                        <img
                            src="automiz_logo.svg" // Replace with your PNG logo path
                            alt="Logo"
                            style={{ width: '110px', marginTop:'-5px'}} // Adjust dimensions as needed
                        />
                    </Navbar.Brand>
                </Col>
                <Col xs={4}>
                    <Nav className="ml-auto"  style={{float:'right'}}>

                            <Button variant="outline-secondary" onClick={handleProfileClick} className="m-1">
                                <img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} />
                            </Button>
                            <Modal show={showProfileModal} onHide={handleProfileClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title> <p style={{fontSize:"15px"}}><img src="/icons/user.png" alt="Profile" style={{ width: '20px', height: '20px' }} /> {auth.user}</p></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div className="dropdown-menu position-static d-flex flex-column flex-lg-row align-items-stretch justify-content-start p-3 rounded-3 shadow-lg" data-bs-theme="light">
                                    <nav className="col-lg-8">
                                    <ul className="list-unstyled d-flex flex-column gap-2">
                                        <li>
                                        <a href="/" className="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg className="bi" width="24" height="24">1</svg>
                                            <div>
                                            <strong className="d-block">Main product</strong>
                                            <small>Take a tour through the product</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" className="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg className="bi" width="24" height="24">2</svg>
                                            <div>
                                            <strong className="d-block">Another product</strong>
                                            <small>Explore this other product we offer</small>
                                            </div>
                                        </a>
                                        </li>
                                        <li>
                                        <a href="/" className="btn btn-hover-light rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                                            <svg className="bi" width="24" height="24">3</svg>
                                            <div>
                                            <strong className="d-block">Support</strong>
                                            <small>Get help from our support crew</small>
                                            </div>
                                        </a>
                                        </li>
                                    </ul>
                                    </nav>
                                    <div className="d-none d-lg-block vr mx-4 opacity-10">&nbsp;</div>
                                    <hr className="d-lg-none"/>
                                    <div className="col-lg-auto pe-3">
                                    <nav>
                                        <ul className="d-flex flex-column gap-2 list-unstyled small">
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Documentation</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Use cases</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">API status</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Partners</a></li>
                                        <li><a href="/" className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover">Resources</a></li>
                                        </ul>
                                    </nav>
                                    </div>
                                </div>
                                </Modal.Body>
                                {/* <Modal.Footer>
                                    <Button variant="secondary" onClick={handleProfileClose}>
                                        Close
                                    </Button>
                                </Modal.Footer> */}
                            </Modal>

                        <Button variant="secondary" onClick={handleLogout} className="m-1">
                            Logout
                        </Button>
                    </Nav>
                </Col>
            </Navbar>
            <Row className=' justify-content-center' style={{padding:'0', margin:'0', height:'98%'}}>
                <Col xs={'12'} sm={'1'} style={{paddingRight:'0'}}>
                    <Nav className="flex-column">
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}} onClick={() => navigate('/home')}>
                            <img src="/icons/Home.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Home
                        </Button>
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}} onClick={() => navigate('/workflows')}>
                            <img src="/icons/Flow.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Workflows
                        </Button>
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}}>
                            <img src="/icons/Check.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Validations
                        </Button>
                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}} onClick={() => navigate('/configuration')} active>
                            <img src="/icons/Config.png" alt="Home" style={{ width: '20px', height: '20px', marginBottom:'3px' }} />
                            <br/>
                            Configuration
                        </Button>

                        <Button variant="light" className="m-1" style={{fontSize:"10px", color:"#000"}}>
                            <img src="/icons/Projects.png" alt="Home" style={{ width: '25px', height: '25px', marginBottom:'3px' }} />
                            <br/>
                            Projects
                        </Button>
                        
                    </Nav>
                </Col>
                <Col xs={'12'} sm={'11'} lg={'11'}>        
                    <div style={{ borderRadius: '12px', border: '1px solid #ccc', height:'99%' }}>
                        <div className="row" style={{padding:'60px'}}>
                            <Col xs={'12'} sm={'12'} className='p-3' style={{borderBottom:' 1px solid #ececec'}}>
                                <h6>{auth.user} Account Configuration</h6>
                            </Col>

                            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#step1">
                                <Row>
                                    {loading ? (
                                        <Col  xs={'12'} sm={'12'} className=" p-5">
                                            <div className="text-center" style={{ marginTop:'calc(11.53%)',  marginBottom:'calc(11.53%)' }}>
                                                <img src="spinner.gif" alt="Loading..." />
                                            </div>
                                        </Col>
                                    ) : (   
                                        <>
                                            <Col xs={'3'} sm={'3'} className='p-4 mt-1' style={{ borderRight: '1px solid #ececec' }}>
                                                <ListGroup>
                                                    <ListGroup.Item className='btn mt-3 rounded-2' style={{borderTopWidth:'thin'}} action href="#step1" onClick={() => handleSelect('#step1')}>Users</ListGroup.Item>
                                                    <ListGroup.Item className='btn mt-3 rounded-2' style={{borderTopWidth:'thin'}} action href="#step2" onClick={() => handleSelect('#step2')}>Client Identification</ListGroup.Item>
                                                    <ListGroup.Item className='btn mt-3 rounded-2' style={{borderTopWidth:'thin'}} action href="#step3" onClick={() => handleSelect('#step3')}>Database</ListGroup.Item>
                                                    <ListGroup.Item className='btn mt-3 rounded-2' style={{borderTopWidth:'thin'}} action href="#step4" onClick={() => handleSelect('#step4')}>Company Info</ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                            <Col xs={'7'} sm={'7'} className='p-4' style={{ height: '410px' }}>
                                                <Tab.Content>
                                                    <Tab.Pane  eventKey="#step1">{renderStepContent()}</Tab.Pane>
                                                    <Tab.Pane  eventKey="#step2">{renderStepContent()}</Tab.Pane>
                                                    <Tab.Pane  eventKey="#step3">{renderStepContent()}</Tab.Pane>
                                                    <Tab.Pane  eventKey="#step4">{renderStepContent()}</Tab.Pane>
                                                </Tab.Content>
                                                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </Tab.Container>

                            <Col xs={'3'} sm={'3'} style={{borderTop:' 1px solid #ececec'}}>
                            </Col>
                            <Col xs={'9'} sm={'9'} className='p-4' style={{borderTop:' 1px solid #ececec'}}>
                            
                                {!loading && activeKey === "#step1" && (
                                    <Button variant="primary me-3" size='sm' style={{ width: '120px' }} onClick={handleNextStep}>Create</Button>
                                )}

                                {!loading && ( activeKey === "#step2" ||  activeKey === "#step3" ||  activeKey === "#step4") && (
                                    <>
                                        <Button variant="primary me-3" size='sm' style={{ width: '120px' }} onClick={handleNextStep}>Continue</Button>
                                        {/* <Button variant="outline-secondary" size='sm' style={{ width: '120px' }} onClick={handlePreviousStep}>Back</Button> */}
                                    </>
                                )}

                            </Col>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        </>
    );
};
export default SetupPage;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

import Login from './Login';
import Register from './Register';
import Setup from './Setup';
import Configure from './Configure';
import Home from './Home';
import ParamsBuilder from './ParamsBuilder';
import DbConfig from './DbConfig';
import OverviewFlow from './OverviewFlow';
import WorkflowsPage from './WorkflowsPage';

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    return (
      <AuthProvider>
        <Router>
            <Routes>
                {/* <Route path="/login" element={<LoginComponent />} /> */}
                <Route path="/" element={<Login />} />
                <Route path="/configure" element={<Setup />} />
                <Route path="/signup" element={<Register />} />
                <Route path="/home" element={<Home />} />
                <Route path="/configuration" element={<Configure />} />
                <Route path="/params" element={<ParamsBuilder />} />
                <Route path="/dbconfig" element={<DbConfig />} />
                <Route path="/workflow" element={<OverviewFlow />} />
                <Route path="/workflows" element={<WorkflowsPage />} />
            </Routes>
        </Router>
      </AuthProvider>
    );
};

export default App;

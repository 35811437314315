import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './context/AuthContext';
import { Navbar, Row, Col, Form } from 'react-bootstrap';

const SingUp = () => {
    const navigate = useNavigate();
    const { auth, login } = useAuth();

    const [formData, setFormData] = useState({
        // firstName: '',
        // lastName: '',
        CompanyName: '',
        email: '',
        password: '',
        retypePassword: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        country: '',
        phone: '',
        termsAccepted: false,
        verificationCode: ''
        
    });
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [hasStartedTyping, setHasStartedTyping] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const [verificationSuccessful, setVerificationSuccessful] = useState(false);
    const [passwordSet, setPasswordSet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });

        if (name === 'password' || name === 'retypePassword') {
            const password = name === 'password' ? value : formData.password;
            const retypePassword = name === 'retypePassword' ? value : formData.retypePassword;
            setPasswordsMatch(password === retypePassword);

            if (name === 'retypePassword' && value !== '') {
                setHasStartedTyping(true);
            }

            if (name === 'termsAccepted') {
                setTermsAccepted(true);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        //console.log('Form Data:', formData);
        try {
            const response = await axios.post('/api/preregister', { formData });
            console.log('Response:', response);
            if (response.status === 200) {
                setVerificationSent(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMessage('Email already exists. Please try to sign in.');
            } else {
                console.error('Login error:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post('/api/register', { formData });
            if (response.status === 200) {
                setVerificationSuccessful(true);
            }
        } catch (error) {
            console.error('Verificaion error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log('Form Data:', formData);
        try {
            const response = await axios.post('/api/register', { formData });
            if (response.status === 200) {
                console.log('Password set successfully', response.data );
                //setPasswordSet(true);
                await loginUser(formData); // Call loginUser after successful registration
                
            }
        } catch (error) {
            console.error('Password submission error:', error);
        } finally {
            setLoading(false);
        }
    };

    const loginUser = async (formData) => {
        try {
            const response = await axios.post('/api/login', {
                username: formData.email,
                password: formData.password,
            });

            // Handle successful login
            if (response.status === 200) {
                //const userData = response.data.user; // Assume your server returns user data
                login(response.data); // Call the login function from the context to save user data
                // Redirect to the workflow page upon successful login
                navigate('/configure');
            }
        } catch (error) {
            // Handle login error
            console.error('Login error:', error);
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                <Navbar.Brand className='text-center'>
                    <img
                        src="automiz_logo.svg"
                        alt="Logo"
                        style={{ width: '210px', margin: '0 0 0 -33px' }}
                    />
                    {/* <img
                        src="Automiz.png"
                        alt="Logo"
                        style={{ width: '50px', height: '50px', margin: '-20px 0 0 -33px' }}
                    />
                    <span style={{ fontSize: '40px', fontWeight: 'bold', color: '#468b2c', paddingLeft: '4px' }}>
                        Automiz
                    </span> */}
                </Navbar.Brand>
                <h5 className="text-center mt-2">Sign Up</h5>
                <Form onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col className="col-md-4 p-5" style={{ borderRight: '1px solid #efefef' }}>
                            <p className='fw-bolder fs-6'>
                                Explore Automiz with a new account.
                            </p>
                            <p className=''>
                                To learn more, visit automiz.io/learn_more
                            </p>
                            <img src="./imgs/learn_more.png" style={{width:'80%'}} alt='Learn More'/>
                        </Col>
                    {loading ? (
                        <Col className="col-md-4 p-5">
                            <div className="text-center" style={{ marginTop:'calc(100%/4)' }}>
                                <img src="spinner.gif" alt="Loading..." />
                            </div>
                        </Col>
                    ) : !verificationSent ? (
                        <Col className="col-md-4 p-5">
                            
                            <div className="mb-3">
                                <label className='fw-bolder fs-6'>Email Address</label>
                                <p style={{fontSize:'12px'}}>Used for account recovery and some administrative functions. </p>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    autoComplete="email"
                                    required={true}
                                />
                            </div>

                            <div className="mb-3">
                                <label className='fw-bolder fs-6'>Company Name</label>
                                <p style={{fontSize:'12px'}}>Choose a name for your account.</p>
                                <input
                                    type="text"
                                    name="CompanyName"
                                    className="form-control"
                                    placeholder="Company Name"
                                    value={formData.CompanyName}
                                    onChange={handleChange}
                                    autoComplete="organization"
                                    required={true}
                                />
                            </div>
                            <div className="d-grid">
                                {errorMessage && (
                                    <div className="alert alert-danger" style={{fontSize:'12px'}} role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    //disabled={!passwordsMatch || !formData.termsAccepted}
                                >
                                    Verify email address
                                </button>
                            </div>
                            <p className="forgot-password text-right">
                                Already registered <a href="/">sign in?</a>
                            </p>
                        </Col>
                    ) : !verificationSuccessful ? (
                        <Col className="col-md-4 p-5">
                            <div className="mb-3">
                                <label className='fw-bolder fs-6'>Confirm you are you</label>
                                <br/><br/>
                                <p>We sent an email with a verification code to <br/> <span style={{fontWeight:'600'}}>{formData.email}</span> <a href='/'>(not you?)</a></p>
                                
                                <p>Enter it below to confirm your email.</p>
                                <input
                                    type="text"
                                    name="verificationCode"
                                    className="form-control"
                                    placeholder="Verification Code"
                                    value={formData.verificationCode}
                                    onChange={handleChange}
                                    required={true}
                                />
                            </div>
                            <div className="d-grid">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleVerificationSubmit}
                                >
                                    Verify
                                </button>
                            </div>
                        </Col>
                    ) : !passwordSet ? (
                        <Col className="col-md-4 p-5">
                            <div className="mb-3">
                                {/* <label>Password</label> */}
                                <label className='fw-bolder fs-6'>Create your password</label>
                                <br/><br/>
                                <p>Your password provides you with sign in access to Automiz, so it's important we get it right.</p>
                                {/* <p>Make sure it's at least 8 characters long. And don't worry, you can always change it later.</p> */}

                                <p>Need to enter same passwored twice to confirm. Submit button will be disabled until password match. </p>
                                <label>Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    style={{ border: hasStartedTyping ? (passwordsMatch ? '2px solid green' : '2px solid red') : '' }}
                                />
                            </div>
                            <div className="mb-3">
                                <label>Retype Password</label>
                                <input
                                    type="password"
                                    name="retypePassword"
                                    className="form-control"
                                    placeholder="Retype password"
                                    value={formData.retypePassword}
                                    onChange={handleChange}
                                    style={{ border: hasStartedTyping ? (passwordsMatch ? '2px solid green' : '2px solid red') : '' }}

                                />
                            </div>
                            <button
                                type="submit"
                                onClick={handlePasswordSubmit}
                                className="btn btn-primary"
                                disabled={!passwordsMatch}
                            >
                                Set Password and Sign Up
                            </button>
                        </Col>
                    ): (
                        <Col className="col-md-4 p-5">
                            <div>
                                <label>Address:</label>
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Save Address
                            </button>
                        </Col>
                    )}
                    </Row>
                    
                </Form>
            </div>
        </div>
    );
};

export default SingUp;
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

function ErrorNode({ id, data }) {
  return (
    <>
        <Handle
            type="target"
            position={Position.Left}
            id="left-handle"
            //style={{ top: '15px' }} // Adjust the position as needed
        />
        
        {/* <div className="error-node__header">
            <strong>Error</strong>
        </div> */}
        <div className="error-node__body">
         Error
        </div>
    </>
  );
}

export default memo(ErrorNode);
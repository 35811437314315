import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, Col, CloseButton } from 'react-bootstrap';

const RegExModal = ({ isOpen, onRequestClose, addRegex }) => {
  const [regexPattern, setRegexPattern] = useState('');
  const [sampleString, setSampleString] = useState('');
  const [testResult, setTestResult] = useState(null);

  const handleTestRegex = () => {
    try {
      const regex = new RegExp(regexPattern);
      const match = sampleString.match(regex);
      setTestResult(match ? `Match: ${match[0]}` : 'No Match');
    } catch (e) {
      setTestResult('Invalid Regex');
    }
  };

  const handleAddRegex = () => {
    addRegex(regexPattern);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="RegEx Builder"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <div className="row">
          <Col xs={10}>
            <h5>RegEx Builder</h5>
          </Col>
          <Col  xs={1}>
            <CloseButton size="sm" onClick={onRequestClose}></CloseButton>
          </Col>
        </div>

      <div className="row">
        <Col xs={9}>
          <label>
            <input
              type="text"
              placeholder='Regex Pattern'
              value={regexPattern}
              onChange={(e) => setRegexPattern(e.target.value)}
            />
          </label>
        </Col>
        <Col xs={2}>
          <Button size="sm" onClick={handleAddRegex}>Add</Button>
        </Col>
      </div>

      <div className="row mt-2">
        <Col xs={9}>
          <label>
            <input
              type="text"
              placeholder='Sample String'
              value={sampleString}
              onChange={(e) => setSampleString(e.target.value)}
            />
          </label>
        </Col>
      </div>

      <button onClick={handleTestRegex}>Test Regex</button>
      {testResult !== null && <div>Result: {testResult}</div>}
    </Modal>
  );
};

export default RegExModal;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './context/AuthContext'; // Import useAuth from your context file
import { Navbar, Col, Form } from 'react-bootstrap';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { auth, login } = useAuth(); // Destructure auth and login from useAuth hook
    const [errorMessage, setErrorMessage] = useState('');

    // Use useEffect to check if the user is already logged in
    useEffect(() => {
        if (auth.user) {
            // Redirect the user if they are already logged in
            navigate('/home');
        }
    }, [auth, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/login', { username, password, }, { withCredentials: true });
            // Handle successful login
            if (response.status === 200) {
                console.log('Login successful:', response.data);
                login(response.data); // Call the login function from the context to save user data
                // Redirect to the workflow page upon successful login
                navigate('/home');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMessage('Login failed. Please check your credentials and try again.');
            } else {
                console.error('Login error:', error);
            }
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                {/* Navbar.Brand for the left side (PNG logo) */}
                <Navbar.Brand href="/" className='text-center'>
                    <img
                            src="automiz_logo.svg"
                            alt="Logo"
                            style={{ width: '210px', margin: '0 0 0 -33px' }}
                    />
                </Navbar.Brand>
                <h5 className="text-center mt-2">Sign In</h5>

                <Col className="col-md-4 p-5" style={{borderRight:'1px solid #efefef'}}>
                    <p className='fw-bolder fs-6'>
                        Explore Automiz with a new account.
                        
                    </p>
                    <p className=''>
                        To learn more, visit automiz.io/learn_more
                    </p>
                    <img src="./imgs/learn_more.png" style={{width:'80%'}} alt='Learn More'/>
                </Col>
                <Col className="col-md-4 p-5" style={{}}>
                    <Form onSubmit={handleSubmit}>
                        {/* <h3>Sign In</h3> */}
                        <div className="mb-3">
                            <label style={{fontSize:'.8rem', fontWeight:'600'}}>Email Address</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                autoComplete="email"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label style={{fontSize:'.8rem', fontWeight:'600'}}>Password</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <div className="custom-control custom-checkbox">
                                <input
                                style={{marginTop:'-5px'}}
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck1"
                                />
                                <label className="custom-control-label" htmlFor="customCheck1" style={{    margin: "-2px 0 0 5px", position: "absolute" }}>
                                Remember me
                                </label>
                            </div>
                        </div>
                        <div className="d-grid">

                            {errorMessage && (
                                <div className="alert alert-danger" style={{fontSize:'12px'}} role="alert">
                                    {errorMessage}
                                </div>
                            )}

                            <button type="submit" className="btn btn-primary">
                                Sign In
                            </button>
                        </div>
                        <p className="forgot-password text-right">
                        Forgot <a href="/">password?</a>
                        </p>
                    </Form>
                    <hr/>
                    <div className="d-grid">
                        <p className="text-secondary" style={{fontSize:'.8rem', fontWeight:'600'}}>New to Automiz?</p>
                        <a href='/signup' className="btn btn-outline-secondary">
                            Create a new Automiz account
                        </a>
                    </div>
                </Col>
                
                
            </div>
        </div>
    );
};

export default Login;

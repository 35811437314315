import React, { memo, useState, useEffect } from 'react';
import { Handle, useReactFlow, Position } from 'reactflow';
import ParamsModal from '../moduls/ParamsModal.jsx';
import CodeEditor from '../components/CodeEditor';
import 'react-resizable/css/styles.css';

function ScriptNode({ id, data }) {
  const [script, setNote] = useState(data?.script || '');
  const [mainInput, setMainInput] = useState(data?.maininput || '');
  const { setNodes } = useReactFlow();

  useEffect(() => {
    if (data) {
      setNote(data.script || '');
      setMainInput(data.maininput || '');
    }
  }, [data]);

  const handleNoteChange = (newCode) => {
    setNote(newCode);
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, script: newCode } } : node
      )
    );
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setMainInput(newValue);
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, maininput: newValue } } : node
      )
    );
  };

  const handleSelect = (selectedValue) => {
    handleInputChange({ target: { value: selectedValue } });
    closeModal("params")();
  };

  const [ParamModalIsOpen, setParamModalIsOpen] = useState(false);

  const openModal = (type) => () => {
    if (type === "params") {
      setParamModalIsOpen(true);
    } 
  };

  const closeModal = (type) => () => {
    if (type === "params") {
      setParamModalIsOpen(false);
    } 
  };

  return (
    <div className="react-flow__node-script">
      <Handle type="source" position={Position.Right} id={`layer-source`} style={{ background: 'green' }} />
      <Handle type="target" position={Position.Left} id={`layer-target`} />
      <Handle type="error" position={Position.Right} id={`layer-error`} style={{ top: '35px', background: 'red' }} />
      <div className="script-node__header">
        <strong>JavaScript</strong>
        <br/>

        <img
          src={`./icons/params.png`} // Adjust the path as needed
          alt="Logo"
          style={{ width: '14px', height: '14px', margin: '0px 4px 0 0', cursor: 'pointer' }} // Adjust dimensions as needed
          onClick={openModal("params")}
        />
        <ParamsModal 
          isOpen={ParamModalIsOpen} 
          onRequestClose={closeModal("params")} 
          onSelect={handleSelect}  
        >
          <div>Params Content</div>
        </ParamsModal>

        <input 
          type="text" 
          value={mainInput || ''} 
          onChange={handleInputChange}
          placeholder="Main Input"
          style={{ color: mainInput?.startsWith('[') && mainInput?.endsWith(']') ? '#ce0d0d' : '#700494' }}
        />

      </div>
      <CodeEditor
        code={script}
        onChange={handleNoteChange}
        language="javascript"
      />
    </div>
  );
}

export default memo(ScriptNode);
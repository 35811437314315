import { MarkerType, Position } from 'reactflow';

export const nodes = [
  
  {
    id: '1',
    type: 'input',
    data: {
      label: 'Start',
    },
    className: 'circle',
    style: {
      background: '#2572cd',
      color: 'white',
    },
    position: { x: 100, y: 200 },
    sourcePosition: Position.Right,
  },
  {
    id: '2',
    type: 'output',
    className: 'circle',
    style: {
      background: '#02b51a',
      color: 'white',
      
    },
    data: {
      label: 'Finish',
    },
    position: { x: 650, y: 200 },
    // sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: '3',
    type: 'warning',
    className: 'circle',
    // style: {
    //   background: '#02b51a',
    //   color: 'white',
      
    // },
    data: {
      label: 'Finish',
    },
    position: { x: 430, y: 100 },
    // sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: '4',
    type: 'error',
    className: 'circle',
    // style: {
    //   background: '#02b51a',
    //   color: 'white',
      
    // },
    data: {
      label: 'Error',
    },
    position: { x: 520, y: 280 },
    // sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
];

export const edges = [
  { id: 'e1-2', source: '1', target: '2', label: 'this is an edge label' },
  { id: 'e1-3', source: '1', target: '3', animated: true },
  { id: 'e1-4', 
    source: '1', 
    target: '4', 
    animated: false, 
    markerEnd: {
        type: MarkerType.ArrowClosed,
      }, 
    },
  {
    id: 'e4-5',
    source: '4',
    target: '5',
    type: 'smoothstep',
    sourceHandle: 'handle-0',
    data: {
      selectIndex: 0,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    id: 'e4-6',
    source: '4',
    target: '6',
    type: 'smoothstep',
    sourceHandle: 'handle-0',
    data: {
      selectIndex: 1,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
];
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

function WarningNode({ id, data }) {
  return (
    <>
        <Handle
            type="target"
            position={Position.Left}
            id="left-handle"
            //style={{ top: '15px' }} // Adjust the position as needed
        />
        <Handle type="source" position={Position.Right} id="right-handle" />
        {/* <div className="warning-node__header">
            <strong>Warning</strong>
        </div> */}
        <div className="warning-node__body">
        Warning
        </div>
    </>
  );
}

export default memo(WarningNode);
import React, { memo, useState, useEffect } from 'react';
import { Handle, useReactFlow, Position } from 'reactflow';

const options = [
  { value: '', label: 'Select an option' },
  { value: 'Add', label: 'Add' },
  { value: 'Remove', label: 'Remove' },
  { value: 'Lock', label: 'Lock' },
  { value: 'Unlock', label: 'Unlock' },
  { value: 'Hide', label: 'Hide' },
  { value: 'UnHide', label: 'UnHide' },
];

function Select({ value, handleId, nodeId, onDeleteHandle, onInputChange, inputValues }) {
  const { setNodes } = useReactFlow();
  return (
    <div className="layer-node__select">
      <input
        type="text"
        value={inputValues[handleId] || ''}
        onChange={(e) => onInputChange(handleId, e.target.value)}
      />
      {handleId !== 'handle-0' && (
        <button className="removeButton" onClick={() => onDeleteHandle(handleId)}>
          -
        </button>
      )}
    </div>
  );
}

function LayerNode({ id, data }) {
  const { setNodes } = useReactFlow();
  const [handles, setHandles] = useState(Object.keys(data.selects));
  const [inputValues, setInputValues] = useState(data.inputFieldsData || {});
  const [handleCounter, setHandleCounter] = useState(handles.length);

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              inputFieldsData: { ...inputValues },
            },
          };
        }
        return node;
      })
    );
  }, [inputValues, id, setNodes]);

  const handleInputChange = (fieldIndex, inputValue) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [fieldIndex]: inputValue,
    }));
  };

  const addHandle = () => {
    const newHandleId = `handle-${handleCounter}`;
    setHandles((prevHandles) => [...prevHandles, newHandleId]);
    setHandleCounter((prevCounter) => prevCounter + 1);

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              selects: {
                ...node.data.selects,
                [newHandleId]: options[0].value,
              },
              inputFieldsData: { ...inputValues },
            },
          };
        }
        return node;
      })
    );
  };

  const deleteHandle = (handleId) => {
    console.log(`Deleting handle with ID: ${handleId}`);
  
    setHandles((prevHandles) => {
      const updatedHandles = prevHandles.filter((id) => id !== handleId);
      console.log(`Updated handles: ${updatedHandles}`);
      return updatedHandles;
    });
  
    setNodes((nodes) => {
      const updatedNodes = nodes.map((node) => {
        if (node.id === id) {
          const updatedInputFieldsData = { ...node.data.inputFieldsData };
          delete updatedInputFieldsData[handleId];
  
          const updatedSelects = { ...node.data.selects };
          console.log(`Before deletion, selects:`, updatedSelects);
          delete updatedSelects[handleId];
          console.log(`After deletion, selects:`, updatedSelects);
  
          console.log(`Updated node data for node ID ${node.id}:`, {
            inputFieldsData: updatedInputFieldsData,
            selects: updatedSelects,
          });
  
          return {
            ...node,
            data: {
              ...node.data,
              inputFieldsData: updatedInputFieldsData,
              selects: updatedSelects,
            },
          };
        }
        return node;
      });
  
      console.log(`Updated nodes:`, updatedNodes);
      return updatedNodes;
    });
  
    setInputValues((prevInputValues) => {
      const newInputValues = { ...prevInputValues };
      delete newInputValues[handleId];
      console.log(`Updated input values:`, newInputValues);
      return newInputValues;
    });
  };

  const handleHeaderSelectChange = (evt) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              headerSelect: evt.target.value,
            },
          };
        }
        return node;
      })
    );
  };

  return (
    <>
      <div className="layer-node__header">
        <strong>Layer</strong>
        <br />
        <select className="nodrag" value={data.headerSelect} onChange={handleHeaderSelectChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Handle type="source" position={Position.Right} id={`layer-source`} />
        <Handle type="target" position={Position.Left} id={`layer-target`} />
      </div>
      <div className="nodrag layer-node__body ">
        {handles.map((handleId) => (
          <Select
            key={handleId}
            nodeId={id}
            value={data.selects[handleId]}
            handleId={handleId}
            onDeleteHandle={deleteHandle}
            onInputChange={handleInputChange}
            inputValues={inputValues}
          />
        ))}
        <button className="addButton" onClick={addHandle}>
          +
        </button>
      </div>
    </>
  );
}

export default memo(LayerNode);